import React from 'react';
import { Link } from 'react-router-dom';

const Pagination = (props) => {
	const { section, page, pages, keyword = '' } = props;
	if (pages <= 1) {
		return null;
	}

	// Definir explícitamente el tipo del arreglo pageLinks
	const pageLinks: JSX.Element[] = [];

	// Añadir página anterior si no es la primera
	if (page > 1) {
		pageLinks.push(
			<li className="page-item" key="prev">
				<Link className="page-link" to={keyword ? `/${section}/search/${keyword}/page/${page - 1}` : `/${section}/page/${page - 1}`}>
					&laquo;
				</Link>
			</li>
		);
	}

	// Añadir página anterior si existe
	if (page > 1) {
		pageLinks.push(
			<li className="page-item" key={page - 1}>
				<Link className="page-link" to={keyword ? `/${section}/search/${keyword}/page/${page - 1}` : `/${section}/page/${page - 1}`}>
					{page - 1}
				</Link>
			</li>
		);
	}

	// Añadir página actual
	pageLinks.push(
		<li className="page-item active" key={page}>
			<Link className="page-link" to="#">
				{page}
			</Link>
		</li>
	);

	// Añadir página siguiente si existe
	if (page < pages) {
		pageLinks.push(
			<li className="page-item" key={page + 1}>
				<Link className="page-link" to={keyword ? `/${section}/search/${keyword}/page/${page + 1}` : `/${section}/page/${page + 1}`}>
					{page + 1}
				</Link>
			</li>
		);
	}

	// Añadir botón de siguiente página si no es la última
	if (page < pages) {
		pageLinks.push(
			<li className="page-item" key="next">
				<Link className="page-link" to={keyword ? `/${section}/search/${keyword}/page/${page + 1}` : `/${section}/page/${page + 1}`}>
					&raquo;
				</Link>
			</li>
		);
	}

	return (
		<nav>
			<ul className="pagination justify-content-center my-md-5 py-md-4">{pageLinks}</ul>
		</nav>
	);
};

export default Pagination;