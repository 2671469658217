import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Message from "../components/LoadingError/Error";
import Loading from "../components/LoadingError/Loading";
import Header from "../components/Header";
import { login } from "../Redux/Actions/userActions";

// Define the state type
interface UserLoginState {
  error: string;
  loading: boolean;
  userInfo: any; // Replace 'any' with the actual type of userInfo
}

// Define the global state type
interface RootState {
  userLogin: UserLoginState;
}

const Login = () => {
  window.scrollTo(0, 0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const redirect = location.search ? (new URLSearchParams(location.search).get('redirect') as string) : '/';

  const userLogin = useSelector((state: RootState) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [userInfo, navigate, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password) as any);
  }

  return (
    <>
      <Header />
      <div className="container d-flex flex-column justify-content-center align-items-center login-center">
        {error && <Message variant="alert-danger">{error}</Message>}
        {loading && <Loading />}
        <form
          className="d-flex row rounded-2 p-4 gap-4 col-md-8 col-lg-4 col-11"
          onSubmit={submitHandler}
          style={{ boxShadow: "0 1px 11px rgba(168, 168, 168, 0.27)" }}
        >
          <input
            type="email"
            placeholder="Email"
            value={email}
            autoComplete="on"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            autoComplete="on"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit" className="btn btn-primary text-uppercase w-100 p-2">Iniciar sesión</button>
          <p>
            <Link to={redirect ? `/register?redirect=${redirect}` : "/register"}>¿Eres nuevo cliente?</Link>
          </p>
        </form>
      </div>
    </>
  );
};

export default Login;