import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../Redux/Actions/userActions';
import { listBannerDetails } from '../Redux/Actions/BannerActions';
import Loading from './LoadingError/Loading';
import Message from './LoadingError/Error';

const Header = () => {
	const [keyword, setKeyword] = useState('');
	const dispatch = useDispatch();
	let navigate = useNavigate();

	const bannerId = '66a3b88222ee65d6c1b169ee';

	const bannerGet = useSelector((state: any) => state.bannerDetails);
	const { loading, error, banner } = bannerGet;

	const cart = useSelector((state: any) => state.cart);
	const { cartItems } = cart;
	const userLogin = useSelector((state: any) => state.userLogin);
	const { userInfo } = userLogin;

	const color = banner.color as string;

	const total: number = Number(
		cartItems
			.reduce((a, i) => {
				let itemTotal = i.qty * i.price;
				if (i.tag !== 'null' && !isNaN(i.priceTag)) {
					itemTotal += i.priceTag;
				}
				if (i.dorsal !== 'null' && !isNaN(i.priceDorsal)) {
					itemTotal += i.priceDorsal;
				}
				return a + itemTotal;
			}, 0)
			.toFixed(2)
	);

	useEffect(() => {
		dispatch(listBannerDetails(bannerId) as any);
	}, [dispatch, bannerId]);

	const logoutHandler = () => {
		dispatch(logout() as any as any);
	};

	const submitHandler = (e) => {
		e.preventDefault();
		if (keyword.trim()) {
			navigate(`/search/${keyword}`);
		} else {
			navigate('/');
		}
	};
	return (
		<div className="d-flex flex-column gap-3">
			{loading ? (
				<div className="mb-5">
					<Loading />
				</div>
			) : error ? (
				<Message variant="alert-danger">{error}</Message>
			) : (
				<>
					{/* Top Header */}
					<div style={{ backgroundColor: color }}>
						<div className="container">
							<div className="row">
								<div className="d-flex align-items-center justify-content-center">
									<span style={{ color: 'white' }}>{banner.name}</span>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
			{/* Header */}
			<div className="container-fluid">
				{/* MOBILE HEADER */}
				<div className="mobile-header">
					<div className="row">
						<div className="d-flex align-items-center justify-content-between">
							<div>
								<Link className="d-flex justify-content-center align-items-center my-2" to="/">
									<img alt="logo" src="/logo.webp" width={144} />
								</Link>
							</div>
							<div className="col-md-3 d-flex align-items-center justify-content-center gap-2">
								{userInfo ? (
									<div className="btn-group dropdown">
										<button type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											{userInfo.name}
										</button>
										<div className="dropdown-menu">
											<Link className="dropdown-item" to="/profile">
												Perfil
											</Link>

											<Link className="dropdown-item" to="#" onClick={logoutHandler}>
												Cerrar sesión
											</Link>
										</div>
									</div>
								) : (
									<div className="btn-group dropdown">
										<button type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<i className="fas fa-user"></i>
										</button>
										<div className="dropdown-menu">
											<Link className="dropdown-item" to="/login">
												Login
											</Link>
											<Link className="dropdown-item" to="/register">
												Register
											</Link>
										</div>
									</div>
								)}
								<Link to="/cart" className="position-relative btn btn-outline-primary">
									{total} € <i className="fas fa-shopping-bag"></i>
									<span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{cartItems.length}</span>
								</Link>
							</div>
						</div>
						<div className="col-12 d-flex align-items-center">
							<form onSubmit={submitHandler} className="d-flex justify-content-center align-items-center rounded-2 gap-2 p-2 w-100 border">
								<input type="search" placeholder="Buscar" className="border border-0 w-100 bg-transparent" onChange={(e) => setKeyword(e.target.value)} />
								<button type="submit" className="border border-0 bg-transparent">
									<i className="fas fa-search"></i>
								</button>
							</form>
						</div>
					</div>
				</div>

				{/* PC HEADER */}
				<div className="pc-header">
					<div className="row">
						<div className="col-md-3 col-4">
							<Link className="d-flex justify-content-center align-items-center my-2" to="/">
								<img alt="logo" src="/logo.webp" width={192} />
							</Link>
						</div>
						<div className="col-md-6 col-8 d-flex align-items-center">
							<form onSubmit={submitHandler} className="d-flex justify-content-center align-items-center rounded-2 gap-2 p-2 w-100 border">
								<input type="search" placeholder="Buscar" className="border border-0 w-100 bg-transparent" onChange={(e) => setKeyword(e.target.value)} />
								<button type="submit" className="border border-0 bg-transparent">
									<i className="fas fa-search"></i>
								</button>
							</form>
						</div>
						<div className="col-md-3 d-flex align-items-center justify-content-center gap-2">
							{userInfo ? (
								<div className="btn-group dropdown">
									<button type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										{userInfo.name}
									</button>
									<div className="dropdown-menu">
										<Link className="dropdown-item" to="/profile">
											Perfil
										</Link>

										<Link className="dropdown-item" to="#" onClick={logoutHandler}>
											Cerrar sesión
										</Link>
									</div>
								</div>
							) : (
								<>
									<Link to="/register">¿Eres nuevo cliente?</Link>
									<Link to="/login" className="btn btn-primary">
										Iniciar sesión
									</Link>
								</>
							)}
							<Link to="/cart" className="position-relative btn btn-outline-primary">
								{total} € <i className="fas fa-shopping-bag"></i>
								<span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{cartItems.length}</span>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
