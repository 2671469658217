import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import { productListReducer, productDetailsReducer, productCreateReviewReducer, productListByLigaReducer, productTopRatedReducer } from './Reducers/ProductReducers';
import { categoryListReducer } from './Reducers/CategoryReducers';
import { questionListReducer } from './Reducers/QuestionReducers';
import { cartReducer } from './Reducers/CartReducers';
import { userDetailsReducer, userLoginReducer, userRegisterReducer, userUpdateProfileReducer } from './Reducers/usersReducers';
import { orderCreateReducer, orderDetailsReducer, orderListMyReducer, orderPayReducer } from './Reducers/OrderReducers';
import { cardListReducer } from './Reducers/CardReducers';
import { bannerDetailsReducer } from './Reducers/BannerReducers';

const reducer: any = {
	productTopList: productTopRatedReducer,
	productListByLiga: productListByLigaReducer,
	productList: productListReducer,
	productDetails: productDetailsReducer,
	productReviewCreate: productCreateReviewReducer,
	categoryList: categoryListReducer,
	cardList: cardListReducer,
	bannerDetails: bannerDetailsReducer,
	questionList: questionListReducer,
	cart: cartReducer,
	userLogin: userLoginReducer,
	userRegister: userRegisterReducer,
	userDetails: userDetailsReducer,
	userUpdateProfile: userUpdateProfileReducer,
	orderCreate: orderCreateReducer,
	orderDetails: orderDetailsReducer,
	orderPay: orderPayReducer,
	orderListMy: orderListMyReducer
};

const cartItemsFromLocalStorage = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems') || '[]') : [];

// login
const userInfoFromLocalStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo') || '{}') : null;

// shippingAddress
const shippingAddressFromLocalStorage = localStorage.getItem('shippingAddress') ? JSON.parse(localStorage.getItem('shippingAddress') || '{}') : {};

const preloadedState = {
	cart: {
		cartItems: cartItemsFromLocalStorage,
		shippingAddress: shippingAddressFromLocalStorage
	},
	userLogin: { userInfo: userInfoFromLocalStorage }
};

const store = configureStore({
	reducer,
	preloadedState,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;