import React from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import PacksSection from "../components/homeComponents/PacksSection";
import Footer from "../components/Footer";

const PacksScreen = () => {
  window.scrollTo(0, 0);
  const { keyword, pagenumber } = useParams();

  return (
    <div>
      <Header />
      <PacksSection keyword={keyword} type={'Pack'} pagenumber={pagenumber} />
      <Footer />
    </div>
  );
};

export default PacksScreen;