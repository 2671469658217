import { CARDS_LIST_FAIL, CARDS_LIST_REQUEST, CARDS_LIST_SUCCESS } from '../Constants/CardConstants';
import axios from 'axios';
import { logout } from './userActions';
import { URL } from '../Url';

export const listCards = () => async (dispatch) => {
	try {
		dispatch({ type: CARDS_LIST_REQUEST });

		const { data } = await axios.get(`${URL}/api/cards/all`);

		dispatch({ type: CARDS_LIST_SUCCESS, payload: data });
	} catch (error: any) {
		const message = error.message && error.response.data.message ? error.response.data.message : error.message;
		if (message === 'Not authorized, token failed') {
			dispatch(logout() as any);
		}
		dispatch({
			type: CARDS_LIST_FAIL,
			payload: message
		});
	}
};
