import React from "react";
import Header from "../components/Header";
import HeaderCategory from "../components/HeaderCategory";
import Reviews from "../components/homeComponents/Reviews";
import FunctionInfo from "../components/homeComponents/FunctionInfo";
import TreeCards from "../components/homeComponents/TreeCards";
import Footer from "../components/Footer";

const HomeScreen = () => {
  window.scrollTo(0, 0);

  return (
    <div>
      <Header />
      <HeaderCategory />
      <FunctionInfo />
      <TreeCards />
      <Reviews />
      <Footer />
    </div>
  );
};

export default HomeScreen;