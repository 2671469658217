import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";

const Orders = (props) => {
  const { loading, error, orders } = props;
  return (
		<div className="d-flex justify-content-center align-items-center flex-column">
			{loading ? (
				<Loading />
			) : error ? (
				<Message variant="alert-danger">{error}</Message>
			) : (
				<>
					{orders.length === 0 ? (
						<div className="d-flex justify-content-center align-items-center gap-3 col-12 alert alert-primary text-center mt-3">
							No Orders
							<button className="btn btn-success">
								<Link to="/" className="text-white text-decoration-none text-uppercase">
									Comprar Ahora
								</Link>
							</button>
						</div>
					) : (
						<div className="table-responsive">
							<table className="table">
								<thead>
									<tr>
										<th>ID</th>
										<th>COPIAR ID</th>
										<th>ESTADO</th>
										<th>FECHA</th>
										<th>TOTAL</th>
									</tr>
								</thead>
								<tbody>
									{orders.map((order) => (
										<tr className={`${order.isPaid ? 'paid' : 'not-paid'}`} key={order._id}>
											<td>
												<a href={`/order/${order._id}`} className="btn btn-outline-primary">
													{order._id}
												</a>
											</td>
											<td>
												<button className="btn btn-outline-primary" onClick={() => navigator.clipboard.writeText(order._id)}>
													Copiar ID
												</button>
											</td>
											<td>{order.isPaid ? 'Pagado' : 'No pagado'}</td>
											<td>{order.isPaid ? moment(order.paidAt).calendar() : moment(order.createdAt).calendar()}</td>
											<td>{order.totalPrice} €</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default Orders;
