import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "./pagination";
import { useDispatch, useSelector } from "react-redux";
import { listProduct } from "../../Redux/Actions/ProductActions";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";
import HeaderCategory from "../HeaderCategory";

const PacksSection = (props) => {
	const { keyword, type, pagenumber } = props;
	const section = 'packs';
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const productList = useSelector((state: any) => state.productList)
  const { loading, error, products, page, pages } = productList;

  useEffect(() => {
    dispatch(listProduct(keyword, type, pagenumber) as any);
	}, [dispatch, keyword, pagenumber]);
  return (
		<>
			<HeaderCategory />
			<div className="container-fluid" style={{ minHeight: '90vh' }}>
				<div className="row">
					<div className="col-lg-12 col-md-12 my-2">
						<div className="row">
							{loading ? (
								<div className="mb-5">
									<Loading />
								</div>
							) : error ? (
								<Message variant="alert-danger">{error}</Message>
							) : (
								<>
									{products
										.filter((product) => product.type === 'Pack')
										.map((product) => (
											<div className="col-lg-3 col-md-6 col-sm-6 my-2" key={product._id}>
												<div className="h-100" style={{ cursor: 'pointer' }}>
													<div onClick={() => navigate(`/products/${product._id}`)}>
														<div className="position-relative overflow-hidden w-100">
															<img className="w-100 h-100 object-fit-contain" src={product.image} alt={product.name} />
														</div>
													</div>

													<div>
														<div className="d-flex justify-content-between align-items-center">
															<span onClick={() => navigate(`/products/${product._id}`)} style={{ fontSize: 19 }}>
																{product.name}
															</span>
															<strong className="d-flex align-items-center" style={{ fontSize: 21 }}>
																{product.price} €
															</strong>
														</div>
													</div>
												</div>
											</div>
										))}
								</>
							)}
							{/* Pagination */}
							<Pagination section={section} pages={pages} page={page} keyword={keyword ? keyword : ''} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PacksSection;