import React from "react";

const Footer = () => {
  return (
		<div className="d-flex flex-column flex-wrap justify-content-around p-4 gap-2" style={{ backgroundColor: '#202225' }}>
			<div>
				<div className="mt-6 px-4 py-6">
					<div className="d-flex flex-wrap justify-content-between">
						<ul>
							<li>
								<h3 style={{ color: 'var(--primary-500)' }}> POLITICAS </h3>
							</li>
							<li>
								<a className="text-decoration-none" href="/privacy-policy">
									<span className="fw-light" style={{ color: 'var(--primary-50)' }}>
										Aviso legal y política de privacidad
									</span>
								</a>
							</li>
							<li>
								<a className="text-decoration-none" href="/cookies-policy">
									<span className="fw-light" style={{ color: 'var(--primary-50)' }}>
										Política de cookies
									</span>
								</a>
							</li>
							<li>
								<a className="text-decoration-none" href="/return-refund-policy">
									<span className="fw-light" style={{ color: 'var(--primary-50)' }}>
										Política de devolución y reembolso
									</span>
								</a>
							</li>
						</ul>
						<ul>
							<li>
								<h3 style={{ color: 'var(--primary-500)' }}>LINKS</h3>
							</li>
							<li>
								<a className="text-decoration-none" href="/preguntas-frecuentes">
									<span className="fw-light" style={{ color: 'var(--primary-50)' }}>
										Preguntas frecuentes
									</span>
								</a>
							</li>
							<li>
								<a className="text-decoration-none" href="/terminos-y-condiciones">
									<span className="fw-light" style={{ color: 'var(--primary-50)' }}>
										Términos y condiciones
									</span>
								</a>
							</li>
						</ul>
						<ul>
							<li>
								<h3 style={{ color: 'var(--primary-500)' }}>AuraFut</h3>
							</li>
							<ul className="d-flex p-0">
								<li className="payment-icons-item">
									<svg xmlns="http://www.w3.org/2000/svg" width="38" height="24" aria-labelledby="pi-google_pay">
										<path d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z" opacity=".07" />
										<path fill="#FFF" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32" />
										<path
											fill="#5F6368"
											d="M18.093 11.976v3.2h-1.018v-7.9h2.691a2.447 2.447 0 0 1 1.747.692 2.28 2.28 0 0 1 .11 3.224l-.11.116c-.47.447-1.098.69-1.747.674l-1.673-.006zm0-3.732v2.788h1.698c.377.012.741-.135 1.005-.404a1.391 1.391 0 0 0-1.005-2.354l-1.698-.03zm6.484 1.348c.65-.03 1.286.188 1.778.613.445.43.682 1.03.65 1.649v3.334h-.969v-.766h-.049a1.93 1.93 0 0 1-1.673.931 2.17 2.17 0 0 1-1.496-.533 1.667 1.667 0 0 1-.613-1.324 1.606 1.606 0 0 1 .613-1.336 2.746 2.746 0 0 1 1.698-.515c.517-.02 1.03.093 1.49.331v-.208a1.134 1.134 0 0 0-.417-.901 1.416 1.416 0 0 0-.98-.368 1.545 1.545 0 0 0-1.319.717l-.895-.564a2.488 2.488 0 0 1 2.182-1.06zM23.29 13.52a.79.79 0 0 0 .337.662c.223.176.5.269.785.263.429-.001.84-.17 1.146-.472.305-.286.478-.685.478-1.103a2.047 2.047 0 0 0-1.324-.374 1.716 1.716 0 0 0-1.03.294.883.883 0 0 0-.392.73zm9.286-3.75-3.39 7.79h-1.048l1.281-2.728-2.224-5.062h1.103l1.612 3.885 1.569-3.885h1.097z"
										/>
										<path fill="#4285F4" d="M13.986 11.284c0-.308-.024-.616-.073-.92h-4.29v1.747h2.451a2.096 2.096 0 0 1-.9 1.373v1.134h1.464a4.433 4.433 0 0 0 1.348-3.334z" />
										<path fill="#34A853" d="M9.629 15.721a4.352 4.352 0 0 0 3.01-1.097l-1.466-1.14a2.752 2.752 0 0 1-4.094-1.44H5.577v1.17a4.53 4.53 0 0 0 4.052 2.507z" />
										<path fill="#FBBC04" d="M7.079 12.05a2.709 2.709 0 0 1 0-1.735v-1.17H5.577a4.505 4.505 0 0 0 0 4.075l1.502-1.17z" />
										<path fill="#EA4335" d="M9.629 8.44a2.452 2.452 0 0 1 1.74.68l1.3-1.293a4.37 4.37 0 0 0-3.065-1.183 4.53 4.53 0 0 0-4.027 2.5l1.502 1.171a2.715 2.715 0 0 1 2.55-1.875z" />
									</svg>
								</li>
								<li className="payment-icons-item">
									<svg xmlns="http://www.w3.org/2000/svg" width="38" height="24" aria-labelledby="pi-maestro">
										<path d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z" opacity=".07" />
										<path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32" />
										<circle cx="15" cy="12" r="7" fill="#EB001B" />
										<circle cx="23" cy="12" r="7" fill="#00A2E5" />
										<path fill="#7375CF" d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z" />
									</svg>
								</li>
								<li className="payment-icons-item">
									<svg xmlns="http://www.w3.org/2000/svg" width="38" height="24" aria-labelledby="pi-master">
										<path d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z" opacity=".07" />
										<path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32" />
										<circle cx="15" cy="12" r="7" fill="#EB001B" />
										<circle cx="23" cy="12" r="7" fill="#F79E1B" />
										<path fill="#FF5F00" d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z" />
									</svg>
								</li>
								<li className="payment-icons-item">
									<svg xmlns="http://www.w3.org/2000/svg" width="38" height="24" aria-labelledby="pi-paypal">
										<path d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z" opacity=".07" />
										<path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32" />
										<path fill="#003087" d="M23.9 8.3c.2-1 0-1.7-.6-2.3-.6-.7-1.7-1-3.1-1h-4.1c-.3 0-.5.2-.6.5L14 15.6c0 .2.1.4.3.4H17l.4-3.4 1.8-2.2 4.7-2.1z" />
										<path fill="#3086C8" d="m23.9 8.3-.2.2c-.5 2.8-2.2 3.8-4.6 3.8H18c-.3 0-.5.2-.6.5l-.6 3.9-.2 1c0 .2.1.4.3.4H19c.3 0 .5-.2.5-.4v-.1l.4-2.4v-.1c0-.2.3-.4.5-.4h.3c2.1 0 3.7-.8 4.1-3.2.2-1 .1-1.8-.4-2.4-.1-.5-.3-.7-.5-.8z" />
										<path fill="#012169" d="M23.3 8.1c-.1-.1-.2-.1-.3-.1-.1 0-.2 0-.3-.1-.3-.1-.7-.1-1.1-.1h-3c-.1 0-.2 0-.2.1-.2.1-.3.2-.3.4l-.7 4.4v.1c0-.3.3-.5.6-.5h1.3c2.5 0 4.1-1 4.6-3.8v-.2c-.1-.1-.3-.2-.5-.2h-.1z" />
									</svg>
								</li>
								<li className="payment-icons-item">
									<svg xmlns="http://www.w3.org/2000/svg" width="38" height="24" aria-labelledby="pi-visa">
										<path d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z" opacity=".07" />
										<path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32" />
										<path
											fill="#142688"
											d="M28.3 10.1H28c-.4 1-.7 1.5-1 3h1.9c-.3-1.5-.3-2.2-.6-3zm2.9 5.9h-1.7c-.1 0-.1 0-.2-.1l-.2-.9-.1-.2h-2.4c-.1 0-.2 0-.2.2l-.3.9c0 .1-.1.1-.1.1h-2.1l.2-.5L27 8.7c0-.5.3-.7.8-.7h1.5c.1 0 .2 0 .2.2l1.4 6.5c.1.4.2.7.2 1.1.1.1.1.1.1.2zm-13.4-.3.4-1.8c.1 0 .2.1.2.1.7.3 1.4.5 2.1.4.2 0 .5-.1.7-.2.5-.2.5-.7.1-1.1-.2-.2-.5-.3-.8-.5-.4-.2-.8-.4-1.1-.7-1.2-1-.8-2.4-.1-3.1.6-.4.9-.8 1.7-.8 1.2 0 2.5 0 3.1.2h.1c-.1.6-.2 1.1-.4 1.7-.5-.2-1-.4-1.5-.4-.3 0-.6 0-.9.1-.2 0-.3.1-.4.2-.2.2-.2.5 0 .7l.5.4c.4.2.8.4 1.1.6.5.3 1 .8 1.1 1.4.2.9-.1 1.7-.9 2.3-.5.4-.7.6-1.4.6-1.4 0-2.5.1-3.4-.2-.1.2-.1.2-.2.1zm-3.5.3c.1-.7.1-.7.2-1 .5-2.2 1-4.5 1.4-6.7.1-.2.1-.3.3-.3H18c-.2 1.2-.4 2.1-.7 3.2-.3 1.5-.6 3-1 4.5 0 .2-.1.2-.3.2M5 8.2c0-.1.2-.2.3-.2h3.4c.5 0 .9.3 1 .8l.9 4.4c0 .1 0 .1.1.2 0-.1.1-.1.1-.1l2.1-5.1c-.1-.1 0-.2.1-.2h2.1c0 .1 0 .1-.1.2l-3.1 7.3c-.1.2-.1.3-.2.4-.1.1-.3 0-.5 0H9.7c-.1 0-.2 0-.2-.2L7.9 9.5c-.2-.2-.5-.5-.9-.6-.6-.3-1.7-.5-1.9-.5L5 8.2z"
										/>
									</svg>
								</li>
							</ul>
						</ul>
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-center">
				<ul>
					<li>
						<span className="fw-light" style={{ color: 'var(--primary-50)' }}>
							Copyright © 2020 - 2024 AuraFut
						</span>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default Footer;
