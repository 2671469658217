import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import "./App.css";
import "./App.scss";
import "./responsive.css";
import "react-toastify/dist/ReactToastify.css";
import HomeScreen from "./screens/HomeScreen";
import ShopScreen from "./screens/ShopScreen";
import PacksScreen from "./screens/PacksScreen";
import FormScreen from "./screens/FormScreen";
import SingleProduct from "./screens/SingleProduct";
import Login from "./screens/Login";
import Register from "./screens/Register";
import CartScreen from "./screens/CartScreen";
import ShippingScreen from "./screens/ShippingScreen";
import ProfileScreen from "./screens/ProfileScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import NotFound from "./screens/NotFound";
import CategoryScreen from "./screens/CategoryScreen";
import SearchScreen from "./screens/SearchScreen";

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route>
			<Route path="/" Component={HomeScreen} />
			<Route path="/categoria-producto/:liga" Component={CategoryScreen} />
			<Route path="/categoria-producto/:liga/page/:pagenumber" Component={CategoryScreen} />
			<Route path="/shop" Component={ShopScreen} />
			<Route path="/shop/page/:pagenumber" Component={ShopScreen} />
			<Route path="/search/:keyword" Component={SearchScreen} />
			<Route path="/search/:keyword/page/:pagenumber" Component={SearchScreen} />
			<Route path="/products/:id" Component={SingleProduct}></Route>
			<Route path="/packs" Component={PacksScreen} />
			<Route path="/packs/page/:pagenumber" Component={PacksScreen} />
			<Route path="/custom-form" Component={FormScreen} />
			<Route path="/login" Component={Login}></Route>
			<Route path="/register" Component={Register}></Route>
			<Route path="/profile" Component={ProfileScreen}></Route>
			<Route path="/cart/:id?" Component={CartScreen}></Route>
			<Route path="/shipping" Component={ShippingScreen}></Route>
			<Route path="/placeorder" Component={PlaceOrderScreen}></Route>
			<Route path="/order/:id" Component={OrderScreen}></Route>
			<Route path="*" Component={NotFound}></Route>
		</Route>
	)
);

const App = () => {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;