import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Rating from '../components/homeComponents/Rating';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Message from '../components/LoadingError/Error';
import { useDispatch, useSelector } from 'react-redux';
import { createProductReview, listProductDetails } from '../Redux/Actions/ProductActions';
import Loading from '../components/LoadingError/Loading';
import { PRODUCT_CREATE_REVIEW_RESET } from '../Redux/Constants/ProductConstants';
import moment from 'moment';

// Define the state types
interface State {
	productDetails: {
		loading: boolean;
		error: string;
		product: any;
	};
	userLogin: {
		userInfo: any;
	};
	productReviewCreate: {
		loading: boolean;
		error: string;
		success: boolean;
	};
}

const SingleProduct = () => {
	const [qty, setQty] = useState(1);
	const [size, setSize] = useState('');
	const [tag, setTag] = useState('');
	const [descartar, setDescartar] = useState('');
	const [dorsal, setDorsal] = useState('');
	const [rating, setRating] = useState(0);
	const [comment, setComment] = useState('');
	const [errorSize, setErrorSize] = useState('');

	const { id: productId } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const productDetails = useSelector((state: State) => state.productDetails);
	const { loading, error, product } = productDetails;
	const userLogin = useSelector((state: State) => state.userLogin);
	const { userInfo } = userLogin;
	const productReviewCreate = useSelector((state: State) => state.productReviewCreate);
	const { loading: loadingCreateReview, error: errorCreateReview, success: successCreateReview } = productReviewCreate;

	useEffect(() => {
		if (successCreateReview) {
			alert('Review created successfully');
			setRating(0);
			setComment('');
			dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
		}
		dispatch(listProductDetails(productId) as any);
	}, [dispatch, productId, successCreateReview]);

	const AddToCartHandle = (e) => {
		e.preventDefault();
		if (!size) {
			setErrorSize('Por favor selecciona una talla.');
			return;
		}
		let navigateUrl = `/cart/${productId}?qty=${qty}&size=${size}`;
		if (tag !== '') {
			navigateUrl += `&tag=${tag}`;
		}
		if (dorsal !== '') {
			navigateUrl += `&dorsal=${dorsal}`;
		}
		navigate(navigateUrl);
	};
	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(
			createProductReview(productId, {
				rating,
				comment
			}) as any
		);
	};
	return (
		<>
			<Header />
			<div className="container single-product">
				{loading ? (
					<Loading />
				) : error ? (
					<Message variant="alert-danger">{error}</Message>
				) : (
					<>
						<div className="row">
							<div className="col-md-6">
								<div className="single-image">
									<img src={product.image} alt={product.name} />
								</div>
							</div>
							<div className="col-md-6">
								<div className="product-dtl">
									<div className="product-info">
										<div className="product-name">{product.name}</div>
									</div>
									<p className="fw-bolder">{product.description}</p>
									<div className="product-count col-lg-7 ">
										<div className="flex-box d-flex justify-content-between align-items-center">
											<h6>Precio</h6>
											<span>{product.price} €</span>
										</div>
										<div className="flex-box d-flex justify-content-between align-items-center">
											<h6>Estado</h6>
											{product.countInStock > 0 ? <span>En Stock</span> : <span>Indisponible</span>}
										</div>
										<div className="flex-box d-flex justify-content-between align-items-center">
											<h6>Reseñas</h6>
											<Rating value={product.rating} text={`${product.numReviews} reseñas`} />
										</div>
										{product.countInStock > 0 ? (
											<>
												<div className="flex-box d-flex justify-content-between align-items-center">
													<h6>Cantidad</h6>
													<select value={qty} onChange={(e) => setQty(Number(e.target.value))}>
														{[...Array(product.countInStock).keys()].map((x) => (
															<option key={x + 1} value={x + 1}>
																{x + 1}
															</option>
														))}
													</select>
												</div>
												{(product.type === 'Ropa' || product.type === 'CustomItem') && (
													<>
														<div className="flex-box flex-column d-flex justify-content-between gap-2">
															<div className="d-flex justify-content-between align-items-center">
																<h6>Tallas</h6>
																<h6 className="cursor-pointer text-decoration-underline" data-bs-toggle="modal" data-bs-target="#exampleModal">
																	Guía de tallas
																</h6>

																<div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
																	<div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
																		<div className="modal-content">
																			<div className="modal-header">
																				<h1 className="modal-title fs-5" id="exampleModalLabel">
																					Guía de tallas
																				</h1>
																				<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
																			</div>
																			<div className="modal-body">
																				<img className="h-100 w-100 border border-1" src="/images/tallas.webp" alt="size" />
																			</div>
																			<div className="modal-footer">
																				<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
																					Cerrar
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="d-flex gap-2">
																{product.sizes.map((sizeOption, index) => (
																	<label key={index} className={`btn btn-outline-primary ${size === sizeOption ? 'active' : ''}`}>
																		<input type="checkbox" className="d-none" checked={size === sizeOption} onChange={() => setSize(sizeOption)} />
																		{sizeOption}
																	</label>
																))}
															</div>
														</div>
													</>
												)}
												{product.type === 'Ropa' && (
													<>
														<div className="flex-box flex-column d-flex justify-content-between">
															<div className="d-flex justify-content-between align-items-center">
																<h6>Nombre</h6>
																<h6>+{product.priceTag}€</h6>
															</div>
															<div className="w-100">
																<input type="text" className="p-1" value={tag} onChange={(e) => setTag(e.target.value)} />
															</div>
														</div>
														<div className="flex-box flex-column d-flex justify-content-between">
															<div className="d-flex justify-content-between align-items-center">
																<h6>Dorsal</h6>
																<h6>+{product.priceDorsal}€</h6>
															</div>
															<div className="w-100">
																<input type="number" className="p-1" value={dorsal} onChange={(e) => setDorsal(e.target.value)} />
															</div>
														</div>
													</>
												)}
												{product.type === 'CustomItem' && (
													<>
														<div className="flex-box flex-column d-flex justify-content-between">
															<div className="d-flex justify-content-between align-items-center">
																<h6>Descarta 3 equipos (Opcional)</h6>
															</div>
															<div className="w-100">
																<input type="text" className="p-1" value={descartar} onChange={(e) => setDescartar(e.target.value)} />
															</div>
														</div>
													</>
												)}
												{errorSize && <Message variant="alert-danger">{errorSize}</Message>}
												{userInfo ? (
													<button onClick={AddToCartHandle} className="btn btn-primary w-100 text-uppercase d-flex align-items-center justify-content-center gap-2">
														<i className="fas fa-shopping-bag"></i>Añadir a la cesta
													</button>
												) : (
													<Link to="/login" className="btn btn-primary w-100 text-uppercase">
														Login
													</Link>
												)}
											</>
										) : null}
									</div>
								</div>
							</div>
						</div>

						{/* RATING */}
						<div className="row my-5">
							<div className="col-md-6">
								<h6 className="mb-3">Reseñas</h6>
								{product.reviews.length === 0 && <Message variant="alert-info">Sin reseñas</Message>}
								{product.reviews.map((review) => (
									<div key={review._id} className="mb-5 mb-md-3 bg-light p-3 shadow-sm rounded">
										<strong>{review.name}</strong>
										<Rating value={review.rating} text={review.comment} />
										<div className="text-muted">{moment(review.createdAt).format('llll')}</div>
										<div className="alert alert-info mt-3">{review.comment}</div>
									</div>
								))}
							</div>
							<div className="col-md-6">
								<h6>ESCRIBA UNA OPINIÓN DE CLIENTE</h6>
								<div className="my-4">
									{loadingCreateReview && <Loading />}
									{errorCreateReview && <Message variant="alert-danger">{errorCreateReview}</Message>}
								</div>
								{userInfo ? (
									<form onSubmit={submitHandler}>
										<div className="my-4">
											<strong>Rating</strong>
											<select value={rating} onChange={(e) => setRating(Number(e.target.value))} className="col-12 bg-light p-3 mt-2 border-0 rounded">
												<option value={0}>Select...</option>
												<option value={1}>1 - ★</option>
												<option value={2}>2 - ★★</option>
												<option value={3}>3 - ★★★</option>
												<option value={4}>4 - ★★★★</option>
												<option value={5}>5 - ★★★★★</option>
											</select>
										</div>
										<div className="my-4">
											<strong>Comment</strong>
											<textarea rows={3} value={comment} onChange={(e) => setComment(e.target.value)} className="col-12 bg-light p-3 mt-2 border-0 rounded"></textarea>
										</div>
										<div className="my-3">
											<button className="col-12 bg-black border-0 p-3 rounded text-white">SUBMIT</button>
										</div>
									</form>
								) : (
									<div className="my-3">
										<Message variant={'alert-warning'}>
											Please{' '}
											<Link to="/login">
												" <strong>Login</strong> "
											</Link>{' '}
											to write a review{' '}
										</Message>
									</div>
								)}
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default SingleProduct;
