import React from "react";
import { Link } from "react-router-dom";

const Reviews = () => {
  return (
		<div className="container-fluid py-2">
			<div className="row">
				<div className="col-12 col-md-4 contact-Box">
					<div className="shadow-sm d-flex flex-column justify-content-center align-items-center rounded-2 p-4 gap-2" style={{ minHeight: 220 }}>
						<div className="d-flex justify-content-center align-items-center border border-5 rounded-circle" style={{ width: 50, height: 50 }}>
							<i className="far fa-clock"></i>
						</div>
						<h5 className="text-uppercase">Envios 7/10 Días</h5>
						<p></p>
					</div>
				</div>
				<div className="col-12 col-md-4 contact-Box">
					<div className="shadow-sm d-flex flex-column justify-content-center align-items-center rounded-2 p-4 gap-2" style={{ minHeight: 220 }}>
						<Link className="d-flex justify-content-center align-items-center border border-5 rounded-circle contact-ig text-decoration-none" style={{ width: 50, height: 50 }} to="https://www.instagram.com/aurafutshop">
							<i className="fab fa-instagram"></i>
						</Link>
						<h5 className="text-uppercase">Atencion 24/7</h5>
					</div>
				</div>
				<div className="col-12 col-md-4 contact-Box">
					<div className="shadow-sm d-flex flex-column justify-content-center align-items-center rounded-2 p-4 gap-2" style={{ minHeight: 220 }}>
						<div className="d-flex justify-content-center align-items-center border border-5 rounded-circle" style={{ width: 50, height: 50 }}>
							<i className="fas fa-shipping-fast"></i>
						</div>
						<h5 className="text-uppercase">Envio gratuito</h5>
						<p></p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Reviews;
