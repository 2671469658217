import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from './pagination';
import { useDispatch, useSelector } from 'react-redux';
import { listProductByLiga } from '../../Redux/Actions/ProductActions';
import { listCategories } from '../../Redux/Actions/CategoryActions';
import Loading from '../LoadingError/Loading';
import Message from '../LoadingError/Error';
import HeaderCategory from '../HeaderCategory';

const CategorySection = (props) => {
	const { keyword, liga, pagenumber } = props;
	const section = `categoria-producto/${liga}`;	
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const productListByLiga = useSelector((state: any) => state.productListByLiga);
	const { loading, error, products, page, pages } = productListByLiga;

	useEffect(() => {
		dispatch(listProductByLiga(liga, pagenumber) as any);
		dispatch(listCategories() as any);
	}, [dispatch, liga, pagenumber]);

	const [sortOrder, setSortOrder] = useState('');
	const handleSortChange = (e) => {
		setSortOrder(e.target.value);
	};

	let sortedProducts = [...products]; // Crear una copia de los productos para ordenar

	if (sortOrder === 'name-asc') {
		sortedProducts.sort((a, b) => a.name.localeCompare(b.name));
	} else if (sortOrder === 'name-desc') {
		sortedProducts.sort((a, b) => b.name.localeCompare(a.name));
	}
	return (
		<>
			<HeaderCategory />
			<div className="container-fluid" style={{ minHeight: '90vh' }}>
				<div className="row d-flex justify-content-end">
					<div className="my-2" style={{ maxWidth: 'max-content' }}>
						<select className="form-select" aria-label="Default select example" value={sortOrder} onChange={handleSortChange}>
							<option value="">Ordenar por popularidad</option>
							<option value="name-asc">Ordenar por nombre: A-Z</option>
							<option value="name-desc">Ordenar por nombre: Z-A</option>
						</select>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12 col-md-12 my-2">
						<div className="row">
							{loading ? (
								<div className="mb-5">
									<Loading />
								</div>
							) : error ? (
								<Message variant="alert-danger">{error}</Message>
							) : (
								<>
									{products.length === 0 ? (
										<div className="d-flex flex-column justify-content-center align-items-center">
											<p>No se encontraron productos.</p>
										</div>
									) : (
										sortedProducts
											.filter((product) => product.liga === liga)
											.map((product) => (
												<div className="col-lg-3 col-md-6 col-sm-6 my-2" key={product._id}>
													<div className="h-100" style={{ cursor: 'pointer' }}>
														<div onClick={() => navigate(`/products/${product._id}`)}>
															<div className="position-relative overflow-hidden w-100">
																<img className="w-100 h-100 object-fit-contain" src={product.image} alt={product.name} />
															</div>
														</div>

														<div>
															<div className="d-flex justify-content-between align-items-center">
																<span onClick={() => navigate(`/products/${product._id}`)} style={{ fontSize: 19 }}>
																	{product.name}
																</span>
																<strong className="d-flex align-items-center" style={{ fontSize: 21 }}>
																	{product.price} €
																</strong>
															</div>
														</div>
													</div>
												</div>
											))
									)}
								</>
							)}
							{/* Pagination */}
							<Pagination section={section} pages={pages} page={page} keyword={keyword ? keyword : ''} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CategorySection;
