import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { listQuestions } from '../../Redux/Actions/QuestionActions';
import Loading from '../LoadingError/Loading';
import Message from '../LoadingError/Error';
import { updateUserProfile } from '../../Redux/Actions/userActions';

interface Question {
	question: string;
	answers: string[];
}

interface Answer {
	questionId: number;
	answer: string;
}

interface QuestionListState {
	loading: boolean;
	error: string | null;
	questions: Question[];
}

interface RootState {
	product: any;
	questionList: QuestionListState;
	userLogin: any;
}

const FormSection = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const questionList = useSelector((state: RootState) => state.questionList);
	const { loading, error, questions } = questionList;

	const userLogin = useSelector((state: RootState) => state.userLogin);
	const { userInfo } = userLogin;

	useEffect(() => {
		dispatch(listQuestions() as any);
	}, [dispatch]);

	// Estado para almacenar las respuestas del usuario
	const [answers, setAnswers] = useState<Answer[]>([]);
	const [currentStep, setCurrentStep] = useState(0);
	const [questions8, setQuestions8] = useState<Question[]>([]);
	const [formSubmitted, setFormSubmitted] = useState(false);

	useEffect(() => {
		if (questions) {
			// Seleccionar 8 preguntas al azar
			const selectedQuestions = [...questions].sort(() => 0.5 - Math.random()).slice(0, 8);
			setQuestions8(selectedQuestions);
			// Inicializar las respuestas
			setAnswers(Array(selectedQuestions.length).fill(null));
		}
	}, [questions]);

	const handleResetAura = () => {

		dispatch(
			updateUserProfile({
				_id: userInfo._id,
				name: userInfo.name,
				email: userInfo.email,
				phone: userInfo.phone,
				aura: []
			}) as any
		);
	};

	const handleAnswerChange = (answer: string) => {
		const newAnswers = [...answers];
		newAnswers[currentStep] = { questionId: currentStep, answer };
		setAnswers(newAnswers);
	};

	const handleNext = () => {
		if (currentStep < questions8.length - 1) {
			setCurrentStep(currentStep + 1);
		}
	};

	const handlePrevious = () => {
		if (currentStep > 0) {
			setCurrentStep(currentStep - 1);
		}
	};

	const handleSubmit = () => {
		if (questions8.every((question) => question !== null)) {
			try {
				const filteredAnswers = answers.filter((answer) => answer !== null);

				dispatch(
					updateUserProfile({
						_id: userInfo._id,
						name: userInfo.name,
						email: userInfo.email,
						phone: userInfo.phone,
						aura: questions8.map((question, index) => ({
								question: question.question,
								answer: filteredAnswers[index]?.answer
						}))
					}) as any
				);
			} catch (error) {
				console.error(error);
				// Manejar el error como prefieras
			}
			setFormSubmitted(true);
		} else {
			alert('Por favor, responde a todas las preguntas antes de enviar el formulario');
		}
	};

	if (userInfo?.aura?.length > 0 && !formSubmitted) {
		return (
			<div className="container-fluid" style={{ minHeight: '90vh' }}>
				<div className="d-flex flex-column align-items-center justify-content-center gap-3">
					<span className="mt-3 alert alert-success">Ya has completado el cuestionario anteriormente. Puedes repetir el cuestionario o ver tus resultados.</span>
					<div className="container-fluid px-md-5">
						<div className="d-flex flex-column flex-md-row gap-4">
							<div className="w-100 my-2">
								<div className="d-flex flex-column p-2 border border-secondary-subtle rounded-2 gap-2" style={{ cursor: 'pointer' }}>
									<div
										onClick={() => {
											setFormSubmitted(false);
											handleResetAura();
										}}
									>
										<div className="position-relative overflow-hidden w-100 rounded-2" style={{ maxHeight: 560, backgroundColor: '#ceefff' }}>
											<img className="w-100 h-auto object-fit-cover" src="https://r2.aurafut.com/custom-form/PestanaRepetirCuestionario.png" alt="" />
										</div>
									</div>
									<span className="d-flex justify-content-center fw-light" style={{ fontSize: 13 }}>
										<div>Repetir cuestionario</div>
									</span>
								</div>
							</div>
							<div className="w-100 my-2">
								<div className="d-flex flex-column p-2 border border-secondary-subtle rounded-2 gap-2" style={{ cursor: 'pointer' }}>
									<div onClick={() => navigate(`/products/6675bfad447170406c427b6a`)}>
										<div className="position-relative overflow-hidden w-100 rounded-2" style={{ maxHeight: 560, backgroundColor: '#ceefff' }}>
											<img className="w-100 h-auto object-fit-cover" src="https://r2.aurafut.com/custom-form/PestanaComprar.png" alt="" />
										</div>
									</div>
									<span className="d-flex justify-content-center fw-light" style={{ fontSize: 13 }}>
										<div>Comprar camiseta de tu AURA</div>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (formSubmitted) {
		return (
			<div className="container-fluid" style={{ minHeight: '90vh' }}>
				<div className="d-flex flex-column align-items-center justify-content-center gap-3">
					<span className="mt-3 alert alert-success">¡Tu AURA ha sido recibida! Haz clic en el botón de abajo para continuar.</span>
					<button className="btn btn-primary mt-3" onClick={() => navigate(`/products/6675bfad447170406c427b6a`)}>
						Comprar camiseta de tu AURA
					</button>
				</div>
			</div>
		);
	}

	return (
		<div className="container-fluid d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '90vh' }}>
			{loading ? (
				<div className="mb-5">
					<Loading />
				</div>
			) : error ? (
				<Message variant="alert-danger">{error}</Message>
			) : (
				<>
					{userInfo ? (
						<>
							<div className="col-auto col-md-9 col-sm-auto">
								<div className="d-flex flex-column justify-content-center align-items-center gap-4 mb-3">
									<h2>{questions8[currentStep]?.question}</h2>
									<div className="content-fluid">
										{questions8[currentStep]?.answers.map((answer, i) => (
											<div key={i} className="d-flex justify-content-start gap-2">
												<input type="radio" id={`question-${currentStep}-answer-${i}`} name={`question-${currentStep}`} value={answer} onChange={() => handleAnswerChange(answer)} checked={answers[currentStep]?.answer === answer} />
												<label className="from" htmlFor={`question-${currentStep}-answer-${i}`}>
													<span></span>
													{answer}
												</label>
											</div>
										))}
									</div>
								</div>
								<div className="d-flex flex-row justify-content-between mt-3">
									<button className="btn btn-outline-secondary" onClick={handlePrevious} disabled={currentStep === 0}>
										Anterior
									</button>
									{currentStep === questions8.length - 1 ? (
										<button className="btn btn-primary" onClick={handleSubmit}>
											Enviar
										</button>
											) : (
													// si no responde a la pregunta actual, deshabilitar el botón de siguiente
													<button className="btn btn-primary" onClick={handleNext} disabled={!answers[currentStep]}>
														Siguiente
													</button>
									)}
								</div>
							</div>
						</>
					) : (
						<div className="d-flex flex-column justify-content-center align-items-center gap-4">
							<h2>Por favor, inicia sesión para continuar</h2>
							<Link to="/login" className="btn btn-primary">
								Iniciar sesión
							</Link>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default FormSection;