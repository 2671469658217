import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { listCards } from '../../Redux/Actions/CardActions';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../LoadingError/Error';
import Loading from '../LoadingError/Loading';

const TreeCards = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// Define tus cards aquí
	const cardList = useSelector((state: any) => state.cardList);
	const { loading, error, cards } = cardList;

	useEffect(() => {
		dispatch(listCards() as any);
	}, [dispatch]);

	return (
		<div className="container-fluid py-2">
			{loading ? (
				<Loading />
			) : error ? (
				<Message variant="alert-danger">{error}</Message>
			) : (
				<>
					<div className="row">
						<div className="col-lg-12 col-md-12 my-2">
							<div className="row">
								{cards.map((card) => (
									<div className="col-lg-4 col-md-6 col-sm-6 my-2" key={card._id}>
										<div className="d-flex flex-column p-2 border border-secondary-subtle rounded-2 gap-2" style={{ cursor: 'pointer' }}>
											<div onClick={() => navigate(card.url)}>
												<div className="position-relative overflow-hidden w-100 rounded-2" style={{ backgroundColor: '#ceefff' }}>
													<img className="w-100 h-100 object-fit-fill" src={card.image} alt={card.name} />
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default TreeCards;
