import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listBannerDetails } from '../../Redux/Actions/BannerActions';
import Message from '../LoadingError/Error';
import Loading from '../LoadingError/Loading';

const FunctionInfo = () => {
	const dispatch = useDispatch();

	const bannerId = '66a3b88222ee65d6c1b169ee';

	const bannerGet = useSelector((state: any) => state.bannerDetails);
	const { loading, error, banner } = bannerGet;

	useEffect(() => {
		dispatch(listBannerDetails(bannerId) as any);
	}, [dispatch, bannerId]);
	return (
		<div className="container-fluid py-2">
			{loading ? (
				<Loading />
			) : error ? (
				<Message variant="alert-danger">{error}</Message>
			) : (
				<>
					<img src={banner.image} alt={banner.name} className="w-100 h-100 rounded" />
				</>
			)}
		</div>
	);
};

export default FunctionInfo;
