import { CART_ADD_ITEM, CART_CLEAR_ITEMS, CART_REMOVE_ITEM, CART_SAVE_PAYMENT_METHOD, CART_SAVE_SHIPPING_ADDRESS } from '../Constants/CartConstants';

interface CardItem {
	product: string;
	name: string;
	image: string;
	price: number;
	countInStock: number;
	qty: number;
}

export const cartReducer = (
	state: { cartItems: CardItem[]; shippingAddress: {} } = {
		cartItems: [],
		shippingAddress: {}
	},
	action
) => {
	switch (action.type) {
		case CART_ADD_ITEM:
			const item = action.payload;
			const existItem = state.cartItems.find((x: CardItem) => x.product === item.product);

			if (existItem) {
				return {
					...state,
					cartItems: state.cartItems.map((x: CardItem) => (x.product === existItem.product ? item : x))
				};
			} else {
				return {
					...state,
					cartItems: [...state.cartItems, item]
				};
			}
		case CART_REMOVE_ITEM:
			return {
				...state,
				cartItems: state.cartItems.filter((x: CardItem) => x.product !== action.payload)
			};
		case CART_SAVE_SHIPPING_ADDRESS:
			return {
				...state,
				shippingAddress: action.payload
			};
		case CART_SAVE_PAYMENT_METHOD:
			return {
				...state,
				paymentMethod: action.payload
			};
		case CART_CLEAR_ITEMS:
			return {
				...state,
				cartItems: []
			};
		default:
			return state;
	}
};
