import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Message from "../components/LoadingError/Error";
import Loading from "../components/LoadingError/Loading";
import { register } from "../Redux/Actions/userActions";
import Header from "../components/Header";

// Define the state type
interface State {
  userRegister: {
    error: string;
    loading: boolean;
    userInfo: any; // Replace 'any' with the actual user info type
  };
}

const Register = () => {
  window.scrollTo(0, 0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
	const redirect = location.search ? (new URLSearchParams(location.search).get('redirect') as string) : '/';

  const userRegister = useSelector((state: State) => state.userRegister);
  const { error, loading, userInfo } = userRegister;

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [userInfo, navigate, redirect]);

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    dispatch(register(name, email, password) as any);
  }

  return (
    <>
      <Header />
      <div className="container d-flex flex-column justify-content-center align-items-center login-center">
        {error && <Message variant="alert-danger">{error}</Message>}
        {loading && <Loading />}

        <form
          className="d-flex row rounded-2 p-4 gap-4 col-md-8 col-lg-4 col-11"
          onSubmit={submitHandler}
          style={{ boxShadow: "0 1px 11px rgba(168, 168, 168, 0.27)" }}
        >
          <input
            type="text"
            placeholder="¿Cual es tu nombre?"
            value={name}
            autoComplete="on"
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            placeholder="E-mail*"
            value={email}
            autoComplete="on"
            onChange={(e) => setEmail(e.target.value)} />
          <input
            type="password"
            placeholder="Contraseña*"
            value={password}
            autoComplete="on"
            onChange={(e) => setPassword(e.target.value)}
          />

          <button type="submit" className="btn btn-primary text-uppercase w-100 p-2">Registrate</button>
          <p>
            <Link
              to={redirect ? `/login?redirect=${redirect}` : "/login"}>
              Tengo cuenta <strong>Iniciar sesión</strong>
            </Link>
          </p>
        </form>
      </div>
    </>
  );
};

export default Register;