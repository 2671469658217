export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST';
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS';
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL';

export const PRODUCT_CREATE_REVIEW_REQUEST = 'PRODUCT_CREATE_REVIEW_REQUEST';
export const PRODUCT_CREATE_REVIEW_SUCCESS = 'PRODUCT_CREATE_REVIEW_SUCCESS';
export const PRODUCT_CREATE_REVIEW_FAIL = 'PRODUCT_CREATE_REVIEW_FAIL';
export const PRODUCT_CREATE_REVIEW_RESET = 'PRODUCT_CREATE_REVIEW_RESET';

export const PRODUCTS_LIST_REQUEST = 'PRODUCTS_LIST_REQUEST';
export const PRODUCTS_LIST_SUCCESS = 'PRODUCTS_LIST_SUCCESS';
export const PRODUCTS_LIST_FAIL = 'PRODUCTS_LIST_FAIL';

export const PRODUCT_TOP_REQUEST = 'PRODUCT_TOP_REQUEST';
export const PRODUCT_TOP_SUCCESS = 'PRODUCT_TOP_SUCCESS';
export const PRODUCT_TOP_FAIL = 'PRODUCT_TOP_FAIL';