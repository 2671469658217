import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { createOrder } from '../Redux/Actions/OrderActions';
import { ORDER_CREATE_RESET } from '../Redux/Constants/OrderConstants';
import Header from '../components/Header';
import Message from '../components/LoadingError/Error';

const PlaceOrderScreen = () => {
	window.scrollTo(0, 0);
	let navigate = useNavigate();

	const dispatch = useDispatch();
	const cart = useSelector((state: any) => state.cart);
	const userLogin = useSelector((state: any) => state.userLogin);
	const { userInfo } = userLogin;

	const [termsAccepted, setTermsAccepted] = useState(false);

	//Calculate Price
	const addDecimals = (num: number) => {
		return (Math.round(num * 100) / 100).toFixed(2);
	};

	let cartCopy = {
		...cart,
		cartItems: cart.cartItems.map((item) => ({
			...item,
			priceTag: item.tag === 'null' ? 0 : item.priceTag,
			priceDorsal: item.dorsal === 'null' ? 0 : item.priceDorsal
		}))
	};

	cartCopy.itemsPrice = Number(addDecimals(cartCopy.cartItems.reduce((acc, item) => acc + item.priceTag + item.priceDorsal + item.price * item.qty, 0))).toFixed(2);
	cartCopy.taxPrice = addDecimals(Number((0 * cartCopy.itemsPrice).toFixed(2)));
	cartCopy.totalPrice = (Number(cartCopy.itemsPrice) + Number(cartCopy.taxPrice)).toFixed(2);

	const orderCreate = useSelector((state: any) => state.orderCreate);
	const { order, success, error } = orderCreate;
	useEffect(() => {
		if (success) {
			navigate(`/order/${order._id}`);
			dispatch({ type: ORDER_CREATE_RESET });
		}
	}, [navigate, dispatch, success, order]);

	const placeOrderHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
		if (cartCopy) {
			dispatch(
				createOrder({
					orderItems: cartCopy.cartItems,
					shippingAddress: cartCopy.shippingAddress,
					paymentMethod: cartCopy.paymentMethod,
					itemsPrice: cartCopy.itemsPrice,
					taxPrice: cartCopy.taxPrice,
					totalPrice: cartCopy.totalPrice
				}) as any
			);
		} else {
			console.error('Cart is undefined');
		}
	};

	return (
		<>
			<Header />
			<div className="container">
				<div className="row order-detail rounded-2">
					<div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
						<div className="row ">
							<div className="col-md-4 center">
								<div className="alert-dark order-box">
									<i className="fas fa-user"></i>
								</div>
							</div>
							<div className="col-md-8 center">
								<h5>
									<strong>Cliente</strong>
								</h5>
								<p>{userInfo.name}</p>
								<p>{userInfo.email}</p>
								<p>{userInfo.phone}</p>
							</div>
						</div>
					</div>
					{/* 2 */}
					<div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
						<div className="row">
							<div className="col-md-4 center">
								<div className="alert-dark order-box">
									<i className="fas fa-truck-moving"></i>
								</div>
							</div>
							<div className="col-md-8 center">
								<h5>
									<strong>Order info</strong>
								</h5>
								<p>Shipping: {cart.shippingAddress.country}</p>
								<p>Pay method: {cart.paymentMethod}</p>
							</div>
						</div>
					</div>
					{/* 3 */}
					<div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
						<div className="row">
							<div className="col-md-4 center">
								<div className="alert-dark order-box">
									<i className="fas fa-map-marker-alt"></i>
								</div>
							</div>
							<div className="col-md-8 center">
								<h5>
									<strong>Deliver to</strong>
								</h5>
								<p>
									Address: {cart.shippingAddress.city}, {cart.shippingAddress.address}, {cart.shippingAddress.postalCode}
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="row order-products justify-content-between">
					<div className="col-lg-8">
						{cart.cartItems.length === 0 ? (
							<Message variant="alert-info mt-5"> Your cart is empty</Message>
						) : (
							<>
								{cartCopy.cartItems.map((item, index) => (
									<div className="order-product row" key={index}>
										<div className="col-md-3 col-6">
											<img src={item.image} alt={item.name} />
										</div>
										<div className="col-md-5 col-6 d-flex align-items-center">
											<Link to={`/products/${item.product}`}>
												<h6>{item.name}</h6>
											</Link>
										</div>
										<div className="mt-3 mt-md-0 col-md-2 col-6  d-flex align-items-center flex-column justify-content-center ">
											<h4>CANTIDAD</h4>
											<h6>{item.qty}</h6>
										</div>
										<div className="mt-3 mt-md-0 col-md-2 col-6 align-items-end  d-flex flex-column justify-content-center ">
											<h4>SUBTOTAL</h4>
											<h6>{Number(item.qty * item.price + item.priceTag + item.priceDorsal).toFixed(2)} €</h6>
										</div>
									</div>
								))}
							</>
						)}
					</div>
					{/* total */}
					<div className="col-lg-3 d-flex align-items-end flex-column mt-5 subtotal-order">
						<table className="table table-bordered">
							<tbody>
								<tr>
									<td>
										<strong>Productos</strong>
									</td>
									<td>{cartCopy.itemsPrice} €</td>
								</tr>
								<tr>
									<td>
										<strong>Envío</strong>
									</td>
									<td className="text-success">Gratis</td>
								</tr>
								<tr>
									<td>
										<strong>Impuestos</strong>
									</td>
									<td>{cartCopy.taxPrice} €</td>
								</tr>
								<tr>
									<td>
										<strong>Total</strong>
									</td>
									<td>{cartCopy.totalPrice} €</td>
								</tr>
							</tbody>
						</table>
						<div className="d-flex flex-column gap-2 w-100">
							<div className="form-check">
								<input className="form-check-input" type="checkbox" onChange={() => setTermsAccepted(!termsAccepted)} id="flexCheckDefault" />
								<label className="form-check-label" htmlFor="flexCheckDefault">
									He leído y acepto las condiciones{' '}
									<Link to="privacy-policy" className="text-success text-decoration-none">
										términos y condiciones
									</Link>
									<span className="text-danger"> *</span>
								</label>
							</div>
							{cart.cartItems.length === 0 ? null : (
								<button type="submit" onClick={placeOrderHandler} className="btn btn-primary text-uppercase w-100" disabled={!termsAccepted}>
									PLACE ORDER
								</button>
							)}
							{error && (
								<div className="my-3 col-12">
									<Message variant="alert-danger">{error}</Message>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PlaceOrderScreen;
