import { QUESTION_LIST_FAIL, QUESTION_LIST_REQUEST, QUESTION_LIST_SUCCESS } from '../Constants/QuestionConstants';
import axios from 'axios';
import { logout } from './userActions';
import { URL } from '../Url';

interface QuestionListRequestAction {
	type: typeof QUESTION_LIST_REQUEST;
}

interface QuestionListSuccessAction {
	type: typeof QUESTION_LIST_SUCCESS;
	payload: any; // Reemplaza 'any' con el tipo correcto para 'payload'
}

interface QuestionListFailAction {
	type: typeof QUESTION_LIST_FAIL;
	payload: string;
}

type DispatchType = (args: QuestionListRequestAction | QuestionListSuccessAction | QuestionListFailAction) => QuestionListRequestAction | QuestionListSuccessAction | QuestionListFailAction;

export const listQuestions = () => async (dispatch: DispatchType) => {
	try {
		dispatch({ type: QUESTION_LIST_REQUEST });

		const { data } = await axios.get(`${URL}/api/questions/all`);

		dispatch({ type: QUESTION_LIST_SUCCESS, payload: data });
	} catch (error: any) {
		const message = error.message && error.response.data.message ? error.response.data.message : error.message;
		if (message === 'Not authorized, token failed') {
			dispatch(logout() as any as any);
		}
		dispatch({
			type: QUESTION_LIST_FAIL,
			payload: message
		});
	}
};
