import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import ProfileTabs from "../components/profileComponents/ProfileTabs";
import { getUserDetails } from "../Redux/Actions/userActions";
import Orders from "../components/profileComponents/Orders";
import moment from "moment";
import { listMyOrders } from "../Redux/Actions/OrderActions";

const ProfileScreen = () => {
  window.scrollTo(0, 0);

  const dispatch = useDispatch();

  const userLogin = useSelector((state: any) => state.userLogin);
  const { userInfo } = userLogin;
  const orderListMy = useSelector((state: any) => state.orderListMy);
  const {loading, error, orders} = orderListMy;

  useEffect(() => {
		if (userInfo) {
			dispatch(listMyOrders() as any);
			dispatch(getUserDetails(userInfo._id) as any);
		}
	}, [dispatch, userInfo]);

  return (
		<>
			<Header />
			<div className="container mt-lg-5 mt-3">
				<div className="row align-items-start">
					<div className="col-lg-4 p-0 shadow ">
						<div className="author-card pb-0 pb-md-3">
							<div className="author-card-cover"></div>
							<div className="author-card-profile row">
								<div className="author-card-avatar col-md-5">
									<img src="./images/user.webp" alt="userprofileimage" />
								</div>
								<div className="author-card-details col-md-7">
									<h5 className="author-card-name mb-2">
										<strong>{userInfo.name}</strong>
									</h5>
									<span className="author-card-position">
										<>Joined {moment(userInfo.createdAt).format('LL')}</>
									</span>
								</div>
							</div>
						</div>
						<div className="wizard pt-3 ">
							<div className="d-flex align-items-start">
								<div className="nav align-items-start flex-column col-12 nav-pills me-3 " id="v-pills-tab" role="tablist" aria-orientation="vertical">
									<button className="active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
										Mis datos
									</button>
									<button className="d-flex justify-content-between" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
										Pedidos, devoluciones y facturas
										<span className="badge rounded-pill bg-danger">{orders ? orders.length : 0}</span>
									</button>
								</div>
							</div>
						</div>
					</div>

					{/* panels */}
					<div className="tab-content col-lg-8 pb-5 pt-lg-0 pt-3" id="v-pills-tabContent">
						<div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
							<ProfileTabs />
						</div>
						<div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
							<Orders orders={orders} loading={loading} error={error} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProfileScreen;
