import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../components/Header';
import { PayPalButton } from 'react-paypal-button-v2';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderDetails, payOrder } from '../Redux/Actions/OrderActions';
import Loading from '../components/LoadingError/Loading';
import Message from '../components/LoadingError/Error';
import moment from 'moment';
import axios from 'axios';
import { ORDER_PAY_RESET } from '../Redux/Constants/OrderConstants';
import { URL } from '../Redux/Url';

const OrderScreen = () => {
	window.scrollTo(0, 0);
	const [sdkReady, setSdkReady] = useState(false);
	const { id: orderId } = useParams();
	const dispatch = useDispatch();

	const orderDetails = useSelector((state: any) => state.orderDetails);
	const { order, loading, error } = orderDetails;
	const orderPay = useSelector((state: any) => state.orderPay);
	const { loading: loadingPay, success: successPay } = orderPay;

	let itemsPrice;
	if (order && !loading) {
		//Calculate Price
		const addDecimals = (num) => {
			return (Math.round(num * 100) / 100).toFixed(2);
		};

		itemsPrice = addDecimals(order.orderItems.reduce((acc, item) => acc + item.priceTag + item.priceDorsal + item.price * item.qty, 0));
	}

	useEffect(() => {
		const addPayPalScript = async () => {
			const { data: clientId } = await axios.get(`${URL}/api/config/paypal`);
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=EUR`;
			script.async = true;
			script.onload = () => {
				setSdkReady(true);
			};
			document.body.appendChild(script);
		};
		if (!order || successPay) {
			dispatch({ type: ORDER_PAY_RESET });
			dispatch(getOrderDetails(orderId) as any);
		} else if (!order.isPaid) {
			if (!window.paypal) {
				addPayPalScript();
			} else {
				setSdkReady(true);
			}
		}
	}, [dispatch, orderId, successPay, order]);

	const successPaymentHandler = (paymentResult) => {
		dispatch(payOrder(orderId, paymentResult) as any);
	};

	return (
		<>
			<Header />
			<div className="container">
				{loading ? (
					<Loading />
				) : error ? (
					<Message variant="alert-danger">{error}</Message>
				) : (
					<>
						<div className="row order-detail rounded-2">
							<div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
								<div className="row">
									<div className="col-md-4 center">
										<div className="alert-success order-box">
											<i className="fas fa-user"></i>
										</div>
									</div>
									<div className="col-md-8 center">
										<h5>
											<strong>Cliente</strong>
										</h5>
										<p>{order.user.name}</p>
										<p>
											<a href={`mailto:${order.user.email}`}>{order.user.email}</a>
										</p>
										<p>{order.user.phone}</p>
									</div>
								</div>
							</div>
							{/* 2 */}
							<div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
								<div className="row">
									<div className="col-md-4 center">
										<div className="alert-success order-box">
											<i className="fas fa-truck-moving"></i>
										</div>
									</div>
									<div className="col-md-8 center">
										<h5>
											<strong>Información del pedido</strong>
										</h5>
										<p>Transporte: {order.shippingAddress.country}</p>
										<p>Método de pago: {order.paymentMethod}</p>
										{order.isPaid ? (
											<div className="bg-info p-2 col-12 rounded-2">
												<p className="text-white text-center text-sm-start">Pagado el {moment(order.paidAt).calendar()}</p>
											</div>
										) : (
											<div className="bg-danger p-2 col-12 rounded-2">
												<p className="text-white text-center text-sm-start">No pagado</p>
											</div>
										)}
									</div>
								</div>
							</div>
							{/* 3 */}
							<div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
								<div className="row">
									<div className="col-md-4 center">
										<div className="alert-success order-box">
											<i className="fas fa-map-marker-alt"></i>
										</div>
									</div>
									<div className="col-md-8 center">
										<h5>
											<strong>Direcciones de envío</strong>
										</h5>
										<p>
											Address: {order.shippingAddress.city}, {order.shippingAddress.address}, {order.shippingAddress.postalCode}
										</p>
										{order.isDelivered ? (
											<div className="bg-info p-2 col-12 rounded-2">
												<p className="text-white text-center text-sm-start">Entregado en {moment(order.deliveredAt).calendar()}</p>
											</div>
										) : (
											<div className="bg-danger p-2 col-12 rounded-2">
												<p className="text-white text-center text-sm-start">No entregado</p>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>

						<div className="row order-products justify-content-between">
							<div className="col-lg-8">
								{order.orderItems.length === 0 ? (
									<Message variant="alert-info mt-5">Your order is empty</Message>
								) : (
									<>
										{order.orderItems.map((item, index) => (
											<div className="order-product row" key={index}>
												<div className="col-md-3 col-6">
													<img src={item.image} alt={item.name} />
												</div>
												<div className="col-md-5 col-6 d-flex align-items-center">
													<Link to={`/products/${item.product}`}>
														<h6>{item.name}</h6>
													</Link>
												</div>
												<div className="mt-3 mt-md-0 col-md-2 col-6  d-flex align-items-center flex-column justify-content-center ">
													<h4>CANTIDAD</h4>
													<h6>{item.qty}</h6>
												</div>
												<div className="mt-3 mt-md-0 col-md-2 col-6 align-items-end  d-flex flex-column justify-content-center ">
													<h4>SUBTOTAL</h4>
													<h6>{item.qty * item.price + item.priceTag + item.priceDorsal} €</h6>
												</div>
											</div>
										))}
									</>
								)}
							</div>
							{/* total */}
							<div className="col-lg-3 d-flex align-items-end flex-column mt-5 subtotal-order">
								<table className="table table-bordered">
									<tbody>
										<tr>
											<td>
												<strong>Productos</strong>
											</td>
											<td>{itemsPrice} €</td>
										</tr>
										<tr>
											<td>
												<strong>Impuestos</strong>
											</td>
											<td>{order.taxPrice} €</td>
										</tr>
										<tr>
											<td>
												<strong>Total</strong>
											</td>
											<td>{order.totalPrice} €</td>
										</tr>
									</tbody>
								</table>
								{!order.isPaid && (
									<div className="col-12">
										{loadingPay && <Loading />}
										{!sdkReady ? <Loading /> : <PayPalButton amount={order.totalPrice} currency={'EUR'} onSuccess={successPaymentHandler} />}
									</div>
								)}
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default OrderScreen;
