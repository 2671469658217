import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './Redux/store';
import App from './App';

// Bootstrap JS
import 'bootstrap/dist/js/bootstrap.bundle.min';

const rootElement = document.getElementById('root');

if (!rootElement) {
	throw new Error('No se encontró el elemento root');
}

const root = ReactDOM.createRoot(rootElement);
root.render(
	<Provider store={store}>
		<App />
	</Provider>
);
