import React from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import ShopSection from "../components/homeComponents/ShopSection";
import Footer from "../components/Footer";
import TopSection from "../components/homeComponents/TopSection";
import HeaderCategory from "../components/HeaderCategory";

const ShopScreen = () => {
  window.scrollTo(0, 0);
  const { keyword, pagenumber } = useParams();

  return (
		<div>
			<Header />
			<HeaderCategory />
			<TopSection />
			<h1 className="text-center text-uppercase p-4">Novedades</h1>
			<ShopSection keyword={keyword} type={'Ropa'} pagenumber={pagenumber} />
			<Footer />
		</div>
	);
};

export default ShopScreen;