import { BANNER_DETAILS_FAIL, BANNER_DETAILS_REQUEST, BANNER_DETAILS_SUCCESS } from '../Constants/BannerConstants';

export const bannerDetailsReducer = (state = { loading: false, banner: {} }, action) => {
	switch (action.type) {
		case BANNER_DETAILS_REQUEST:
			return { ...state, loading: true };
		case BANNER_DETAILS_SUCCESS:
			return { loading: false, banner: action.payload };
		case BANNER_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};