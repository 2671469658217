import React from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import ShopSection from "../components/homeComponents/ShopSection";
import Footer from "../components/Footer";
import HeaderCategory from "../components/HeaderCategory";

const SearchScreen = () => {
	window.scrollTo(0, 0);
	const { keyword, pagenumber } = useParams();

	return (
		<div>
			<Header />
			<HeaderCategory />
			<ShopSection keyword={keyword} pagenumber={pagenumber} />
			<Footer />
		</div>
	);
};

export default SearchScreen;