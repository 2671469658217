import axios from 'axios';
import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_SAVE_PAYMENT_METHOD, CART_SAVE_SHIPPING_ADDRESS } from '../Constants/CartConstants';
import { Dispatch } from 'redux';
import { URL } from '../Url';

// Definimos las interfaces para los tipos de datos
interface Product {
	_id: string;
	name: string;
	image: string;
	price: number;
	priceTag: number;
	priceDorsal: number;
	countInStock: number;
}

interface ShippingAddress {
	address: string;
	city: string;
	postalCode: string;
	country: string;
}

// Actualizamos las acciones
export const addToCart = (id: string, qty: number, size: string, tag: string, dorsal: string) => async (dispatch: any, getState: any) => {
	const { data } = await axios.get<Product>(`${URL}/api/products/${id}`);

	dispatch({
		type: CART_ADD_ITEM,
		payload: {
			product: data._id,
			name: data.name,
			image: data.image,
			price: data.price,
			priceTag: data.priceTag,
			priceDorsal: data.priceDorsal,
			countInStock: data.countInStock,
			qty,
			size,
			tag,
			dorsal
		}
	});

	localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

export const removeFromCart = (id: string) => (dispatch: any, getState: any) => {
	dispatch({
		type: CART_REMOVE_ITEM,
		payload: id
	});

	localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

export const saveShippingAddress = (data: ShippingAddress) => (dispatch: Dispatch) => {
	dispatch({
		type: CART_SAVE_SHIPPING_ADDRESS,
		payload: data
	});

	localStorage.setItem('shippingAddress', JSON.stringify(data));
};

export const savePaymentMethod = (data: string) => (dispatch: Dispatch) => {
	dispatch({
		type: CART_SAVE_PAYMENT_METHOD,
		payload: data
	});

	localStorage.setItem('paymentMethod', JSON.stringify(data));
};
