import { PRODUCT_LIST_REQUEST, PRODUCT_LIST_SUCCESS, PRODUCT_LIST_FAIL, PRODUCT_DETAILS_REQUEST, PRODUCT_DETAILS_SUCCESS, PRODUCT_DETAILS_FAIL, PRODUCT_CREATE_REVIEW_REQUEST, PRODUCT_CREATE_REVIEW_SUCCESS, PRODUCT_CREATE_REVIEW_FAIL, PRODUCT_CREATE_REVIEW_RESET, PRODUCTS_LIST_REQUEST, PRODUCTS_LIST_SUCCESS, PRODUCTS_LIST_FAIL, PRODUCT_TOP_REQUEST, PRODUCT_TOP_SUCCESS, PRODUCT_TOP_FAIL } from '../Constants/ProductConstants';

// Define the Review interface
interface Review {
	name: string;
	rating: number;
	comment: string;
	user: string; // This is an ObjectId in mongoose, but we'll use string in TypeScript
}

// Define the Product interface
interface Product {
	type: string;
	liga: string;
	name: string;
	image?: string;
	description: string;
	reviews: Review[];
	rating: number;
	numReviews: number;
	price: number;
	countInStock: number;
}

// Define the state shape for product list
interface ProductListState {
	loading?: boolean;
	products?: Product[];
	pages?: number;
	page?: number;
	error?: string;
}

// Define the state shape for product details
interface ProductDetailsState {
	loading?: boolean;
	product?: Product;
	error?: string;
}

// Define the state shape for product review creation
interface ProductCreateReviewState {
	loading?: boolean;
	success?: boolean;
	error?: string;
}

// PRODUCT LIST
export const productListReducer = (state: ProductListState = { loading: false, products: [] }, action: any): ProductListState => {
	switch (action.type) {
		case PRODUCT_LIST_REQUEST:
			return { loading: true, products: [] };
		case PRODUCT_LIST_SUCCESS:
			return {
				loading: false,
				pages: action.payload.pages,
				page: action.payload.page,
				products: action.payload.products
			};
		case PRODUCT_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

// PRODUCT LIST BY LIGA
export const productListByLigaReducer = (state: ProductListState = { loading: false, products: [] }, action: any): ProductListState => {
	switch (action.type) {
		case PRODUCTS_LIST_REQUEST:
			return { loading: true, products: [] };
		case PRODUCTS_LIST_SUCCESS:
			return {
				loading: false,
				pages: action.payload.pages,
				page: action.payload.page,
				products: action.payload.products
			};
		case PRODUCTS_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

// TOP SELLING PRODUCTS
export const productTopRatedReducer = (state: ProductListState = { loading: false, products: [] }, action: any): ProductListState => {
	switch (action.type) {
		case PRODUCT_TOP_REQUEST:
			return { loading: true, products: [] };
		case PRODUCT_TOP_SUCCESS:
			return { loading: false, products: action.payload };
		case PRODUCT_TOP_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

// SINGLE PRODUCT
export const productDetailsReducer = (
	state: ProductDetailsState = {
		loading: false,
		product: {
			type: '',
			liga: '',
			name: '',
			description: '',
			reviews: [],
			rating: 0,
			numReviews: 0,
			price: 0,
			countInStock: 0
		}
	},
	action: any
): ProductDetailsState => {
	switch (action.type) {
		case PRODUCT_DETAILS_REQUEST:
			return { ...state, loading: true };
		case PRODUCT_DETAILS_SUCCESS:
			return { loading: false, product: action.payload };
		case PRODUCT_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

// PRODUCT CREATE REVIEW
export const productCreateReviewReducer = (state: ProductCreateReviewState = { loading: false }, action: any): ProductCreateReviewState => {
	switch (action.type) {
		case PRODUCT_CREATE_REVIEW_REQUEST:
			return { loading: true };
		case PRODUCT_CREATE_REVIEW_SUCCESS:
			return { loading: false, success: true };
		case PRODUCT_CREATE_REVIEW_FAIL:
			return { loading: false, error: action.payload };
		case PRODUCT_CREATE_REVIEW_RESET:
			return {};
		default:
			return state;
	}
};
