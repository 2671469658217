import React, { useEffect } from "react";
import Header from "../components/Header";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../Redux/Actions/cartActions";

// Definimos las interfaces para los tipos de datos
interface CartItem {
	product: string;
	image: string;
	name: string;
	qty: number;
	size: string;
	tag: string;
	dorsal: string;
	price: number;
	priceTag: number;
	priceDorsal: number;
	countInStock: number;
}

interface CartState {
  cartItems: CartItem[];
}

const CartScreen = () => {
  window.scrollTo(0, 0);
  const dispatch = useDispatch();
  const { id: productId } = useParams();
  const location = useLocation();
	const qty = location.search ? Number(new URLSearchParams(location.search).get('qty')) : 1;
	const size = location.search ? String(new URLSearchParams(location.search).get('size')) : '';
	const tag = location.search ? String(new URLSearchParams(location.search).get('tag')) : 'null';
	const dorsal = location.search ? String(new URLSearchParams(location.search).get('dorsal')) : 'null';

  const cart = useSelector((state: { cart: CartState }) => state.cart);
	const { cartItems } = cart;

  const total: number = Number(
		cartItems
			.reduce((a, i) => {
				let itemTotal = i.qty * i.price;
				if (i.tag !== 'null' && !isNaN(i.priceTag)) {
					itemTotal += i.priceTag;
				}
				if (i.dorsal !== 'null' && !isNaN(i.priceDorsal)) {
					itemTotal += i.priceDorsal;
				}
				return a + itemTotal;
			}, 0)
			.toFixed(2)
	);

	//No sumar el precio de la etiqueta y el dorsal si no se ha seleccionado

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty, size, tag, dorsal) as any);
    }
  }, [dispatch, productId, qty, size, tag, dorsal]);

  const navigate = useNavigate();
  const checkOutHandler = () => {
    navigate("/shipping");
  };

  const removeFromCartHandle = (id: string) => {
    dispatch(removeFromCart(id) as any);
  };
  return (
		<>
			<Header />
			{/*Cart*/}
			<div className="container">
				{cartItems.length === 0 ? (
					<div className="d-flex align-items-center justify-content-center mt-3 gap-3 alert alert-primary">
						No hay productos en tu carrito
						<button className="btn btn-success">
							<Link to="/" className="text-white text-decoration-none text-uppercase">
								Comprar Ahora
							</Link>
						</button>
					</div>
				) : (
					<>
						<div className="d-flex align-items-center justify-content-center mt-3 alert alert-primary">Total de productos del carrito: {cartItems.length}</div>
						{/*cartiterm*/}
						{cartItems.map((item) => (
							<div key={item.product} className="cart-iterm row p-4 rounded-2">
								<div className="mb-2">
									<button onClick={() => removeFromCartHandle(item.product)} className="btn btn-outline-dark">
										<i className="fas fa-times"></i>
									</button>
								</div>
								<div className="cart-image col-md-2">
									<img src={item.image} alt={item.name} />
								</div>
								<div className="cart-text col-md-3 d-flex align-items-center">
									<Link to={`/products/${item.product}`}>
										<h4>{item.name}</h4>
									</Link>
								</div>
								<div className="cart-qty col-md-2 col-sm-3 mt-md-3 mt-3 mt-md-0 d-flex flex-column justify-content-center">
									<h6>CANTIDAD</h6>
									<select value={item.qty} onChange={(e) => dispatch(addToCart(item.product, Number(e.target.value), item.size, item.tag, item.dorsal) as any)}>
										{[...Array(item.countInStock).keys()].map((x) => (
											<option key={x + 1} value={x + 1}>
												{x + 1}
											</option>
										))}
									</select>
								</div>
								<div className="cart-qty col-md-1 col-sm-3 mt-md-2 mt-3 mt-md-0 d-flex flex-column justify-content-center">
									<h6>TALLA</h6>
									<h5>{item.size}</h5>
								</div>
								{item.tag !== 'null' && (
									<div className="cart-qty col-md-1 col-sm-3 mt-md-2 mt-3 mt-md-0 d-flex flex-column justify-content-center">
										<h6 style={{ minWidth: 'max-content' }}>NOMBRE +{item.priceTag}€</h6>
										<h5>{item.tag}</h5>
									</div>
								)}
								{item.dorsal !== 'null' && (
									<div className="cart-qty col-md-1 col-sm-3 mt-md-2 mt-3 mt-md-0 d-flex flex-column justify-content-center">
										<h6 style={{ minWidth: 'max-content' }}>DORSAL +{item.priceDorsal}€</h6>
										<h5>{item.dorsal}</h5>
									</div>
								)}
								<div className="cart-price col-md-1 col-sm-3 mt-md-2 mt-3 mt-md-0 d-flex flex-column justify-content-center">
									<h6>PRECIO</h6>
									<h5>{item.price}€</h5>
								</div>
							</div>
						))}

						{/* End of card items */}
						<div className="total">
							<span className="sub">Total</span>
							<span className="total-price">{total} €</span>
						</div>
						<hr />
						<div className="cart-buttons d-flex align-items-center row">
							<Link to="/" className="col-md-6">
								<button className="btn btn-outline-dark text-uppercase">Seguir comprando</button>
							</Link>
							{total > 0 && (
								<div className="col-md-6 d-flex justify-content-md-end mt-3 mt-md-0">
									<button onClick={checkOutHandler} className="btn btn-primary text-uppercase w-100">
										REALIZAR PEDIDO
									</button>
								</div>
							)}
						</div>
					</>
				)}
			</div>
		</>
	);
}

export default CartScreen;
