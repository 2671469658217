import { BANNER_DETAILS_FAIL, BANNER_DETAILS_REQUEST, BANNER_DETAILS_SUCCESS } from '../Constants/BannerConstants';
import axios from 'axios';
import { URL } from '../Url';

// SINGLE BANNER
export const listBannerDetails = (id) => async (dispatch) => {
	try {
		dispatch({ type: BANNER_DETAILS_REQUEST });
		const { data } = await axios.get(`${URL}/api/banners/${id}`);
		dispatch({ type: BANNER_DETAILS_SUCCESS, payload: data });
	} catch (error: any) {
		dispatch({
			type: BANNER_DETAILS_FAIL,
			payload: error.message && error.response.data.message ? error.response.data.message : error.message
		});
	}
};
