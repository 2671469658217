import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { listTopProducts } from '../../Redux/Actions/ProductActions';
import Loading from '../LoadingError/Loading';
import Message from '../LoadingError/Error';

const TopSection = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const productTopList = useSelector((state: any) => state.productTopList);
	const { loading, error, products } = productTopList;

	useEffect(() => {
		dispatch(listTopProducts() as any);
	}, [dispatch]);

	let sortedProducts = [...products];

	// Filtrar y ordenar los productos por ventas y tomar los primeros 4
	const topSellingProducts = sortedProducts.sort((a, b) => b.sales - a.sales).slice(0, 4);

	return (
		<>
			<div className="container-fluid" style={{ minHeight: '80vh' }}>
				<div className="row">
					<div className="col-lg-12 col-md-12 my-2">
						<h1 className="text-center text-uppercase p-4">Top ventas</h1>
						<div className="row">
							{loading ? (
								<div className="mb-5">
									<Loading />
								</div>
							) : error ? (
								<Message variant="alert-danger">{error}</Message>
							) : (
								<>
									{topSellingProducts.length === 0 ? (
										<div className="d-flex flex-column justify-content-center align-items-center">
											<p>No se encontraron productos.</p>
										</div>
									) : (
										topSellingProducts.map((product) => (
											<div className="col-lg-3 col-md-6 col-sm-6 my-2" key={product._id}>
												<div className="h-100" style={{ cursor: 'pointer' }}>
													<div onClick={() => navigate(`/products/${product._id}`)}>
														<div className="position-relative overflow-hidden w-100">
															<img className="w-100 h-100 object-fit-contain" src={product.image} alt={product.name} />
														</div>
													</div>

													<div>
														<div className="d-flex justify-content-between align-items-center">
															<span onClick={() => navigate(`/products/${product._id}`)} style={{ fontSize: 19 }}>
																{product.name}
															</span>
															<strong className="d-flex align-items-center" style={{ fontSize: 21 }}>
																{product.price} €
															</strong>
														</div>
													</div>
												</div>
											</div>
										))
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default TopSection;
