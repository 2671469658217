import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import FormSection from "../components/formComponent/FormSection";

const FormScreen = () => {
  window.scrollTo(0, 0);

  return (
    <div>
      <Header />
      <FormSection />
      <Footer />
    </div>
  );
};

export default FormScreen;