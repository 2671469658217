import {
  CARDS_LIST_FAIL,
  CARDS_LIST_REQUEST,
  CARDS_LIST_SUCCESS,
} from "../Constants/CardConstants";

interface Card {
	_id: string;
	name: string;
	image: string;
	url: string;
}

// ALL CATEGORIES
export const cardListReducer = (state: { cards: Card[]; loading?: boolean; error?: any } = { cards: [] }, action: any) => {
	switch (action.type) {
		case CARDS_LIST_REQUEST:
			return { loading: true, cards: [] };
		case CARDS_LIST_SUCCESS:
			return { loading: false, cards: action.payload };
		case CARDS_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};