import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { listCategories } from '../Redux/Actions/CategoryActions';
import Loading from './LoadingError/Loading';
import Message from './LoadingError/Error';

interface Category {
	_id: string;
	name: string;
	image: string;
	type: string;
}

const HeaderCategory = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const categoryList = useSelector((state: any) => state.categoryList);
	const { loading, error, categories } = categoryList;
	const [ligas, setLigas] = useState<Category[]>([]);

	useEffect(() => {
		dispatch(listCategories() as any);
	}, [dispatch]);

	useEffect(() => {
		if (categories) {
			setLigas(categories.filter((category) => category.type === 'Liga'));
		}
	}, [categories]);

	return (
		<div>
			{/* Header */}
			<div className="container-fluid">
				{/* MOBILE HEADER */}
				<div className="mobile-header border border-1 rounded-2">
					<div id="carouselExample" className="carousel slide d-flex">
						<button className="btn btn-primary" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
							<span aria-hidden="true">
								<i className="fas fa-chevron-left"></i>
							</span>
							<span className="visually-hidden">Previous</span>
						</button>
						<div className="carousel-inner">
							{ligas.map((liga, index) => (
								<div className={`carousel-item ${index === 0 ? 'active' : ''}`} style={{ cursor: 'pointer' }} onClick={() => navigate(`/categoria-producto/${liga.name}`)} key={liga._id}>
									<div className="d-flex align-items-center justify-content-center gap-2">
										<div className="position-relative overflow-hidden" style={{ height: 50, width: 50 }}>
											<img className="w-100 h-100 object-fit-contain" src={liga.image} alt={liga.name} />
										</div>
										<strong style={{ fontSize: 13 }}>{liga.name}</strong>
									</div>
								</div>
							))}
						</div>
						<button className="btn btn-primary" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
							<span aria-hidden="true">
								<i className="fas fa-chevron-right"></i>
							</span>
							<span className="visually-hidden">Next</span>
						</button>
					</div>
				</div>

				{/* PC HEADER */}
				<div className="pc-header border border-1 rounded-2 my-3">
					<div className="d-flex align-items-center gap-2 justify-content-around">
						{loading ? (
							<div className="mb-5">
								<Loading />
							</div>
						) : error ? (
							<Message variant="alert-danger">{error}</Message>
						) : (
							<>
								{ligas.map((liga) => (
									<div className="d-flex flex-column align-items-center p-2 gap-1" style={{ cursor: 'pointer' }} onClick={() => navigate(`/categoria-producto/${liga.name}`)} key={liga._id}>
										<div className="d-flex align-items-center position-relative overflow-hidden" style={{ height: 50, width: 50 }}>
											<img className="w-100 h-100 object-fit-contain" src={liga.image} alt={liga.name} />
										</div>
										<div className="d-flex justify-content-center">
											<strong style={{ fontSize: 13 }}>{liga.name}</strong>
										</div>
									</div>
								))}
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeaderCategory;
