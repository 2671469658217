import {
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
} from "../Constants/CategoryConstants";

interface Category {
	type: string;
	name: string;
	image: string;
	description: string;
}

// ALL CATEGORIES
export const categoryListReducer = (state: { categories: Category[]; loading?: boolean; error?: any } = { categories: [] }, action: any) => {
	switch (action.type) {
		case CATEGORY_LIST_REQUEST:
			return { loading: true, categories: [] };
		case CATEGORY_LIST_SUCCESS:
			return { loading: false, categories: action.payload };
		case CATEGORY_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};