import React from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CategorySection from "../components/homeComponents/CategorySection";

const CategoryScreen = () => {
  window.scrollTo(0, 0);
  const { keyword, liga, pagenumber } = useParams();

  return (
		<div>
			<Header />
			<CategorySection keyword={keyword} liga={liga} pagenumber={pagenumber} />
			<Footer />
		</div>
	);
};

export default CategoryScreen;