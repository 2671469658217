import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import Header from '../components/Header';
import { savePaymentMethod, saveShippingAddress } from '../Redux/Actions/cartActions';
import { useNavigate } from 'react-router-dom';
import { getUserDetails, updateUserProfile } from '../Redux/Actions/userActions';
import Message from '../components/LoadingError/Error';

const ShippingScreen = () => {
	const navigate = useNavigate();
	const dispatch: Dispatch<any> = useDispatch();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const cart = useSelector((state: any) => state.cart);
	const { shippingAddress } = cart;
	if (!shippingAddress) {
		navigate('/shipping');
	}

	const [formData, setFormData] = useState({
		address: shippingAddress.address || '',
		city: shippingAddress.city || '',
		province: shippingAddress.province || '',
		postalCode: shippingAddress.postalCode || '',
		country: shippingAddress.country || '',
		phone: ''
	});

	const [errors, setErrors] = useState({
		address: '',
		city: '',
		province: '',
		postalCode: '',
		country: '',
		phone: ''
	});

	const userLogin = useSelector((state: any) => state.userLogin);
	const { userInfo } = userLogin;

	useEffect(() => {
		if (userInfo) {
			dispatch(getUserDetails(userInfo._id) as any);
		}
	}, [dispatch, userInfo]);

	const userDetails = useSelector((state: any) => state.userDetails);
	const { user } = userDetails;

	useEffect(() => {
		if (user) {
			setFormData((prevData) => ({ ...prevData, phone: user.phone || '' }));
		}
	}, [user]);

	const validateFields = () => {
		const newErrors = {
			address: formData.address ? '' : 'La dirección es obligatoria',
			city: formData.city ? '' : 'La ciudad es obligatoria',
			province: formData.province ? '' : 'La provincia es obligatoria',
			postalCode: formData.postalCode ? '' : 'El código postal es obligatorio',
			country: formData.country ? '' : 'El país es obligatorio',
			phone: formData.phone ? '' : 'El teléfono es obligatorio'
		};

		setErrors(newErrors);

		// Si no hay errores, retornamos true
		return !Object.values(newErrors).some((error) => error);
	};

	const submitHandler = (e) => {
		e.preventDefault();
		if (validateFields()) {
			dispatch(saveShippingAddress(formData));
			dispatch(savePaymentMethod('Paypal') as any);
			dispatch(updateUserProfile({ _id: user._id, phone: formData.phone }) as any);
			navigate('/placeorder');
		}
	};

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.id]: e.target.value });
	};

	return (
		<>
			<Header />
			<div className="container-fluid d-flex justify-content-center align-items-center login-center">
				<div className="d-flex flex-column flex-md-row rounded-2 gap-4 col-md-8 col-lg-8 col-11" style={{ boxShadow: '0 1px 11px rgba(168, 168, 168, 0.27)' }}>
					<div className="w-100 bg-primary text-white p-5 rounded-2">
						<h1 className="text-3xl font-bold mb-4">Dirección de entrega</h1>
						<p className="text-lg mb-8">Ingrese sus datos de envío para continuar.</p>
						<div className="d-flex gap-4">
							<div className="d-flex flex-col items-center">
								<span className="text-lg font-medium">Dirección</span>
							</div>
							<div className="d-flex flex-col items-center">
								<span className="text-lg font-medium">Entrega</span>
							</div>
							<div className="d-flex flex-col items-center">
								<span className="text-lg font-medium">Contacto</span>
							</div>
						</div>
					</div>
					<div className="p-5">
						<form onSubmit={submitHandler} className="d-flex row gap-4">
							<div>
								<label htmlFor="address" className="font-medium">
									Dirección*
								</label>
								<input type="text" id="address" value={formData.address} onChange={handleChange} placeholder="Dirección" />
								{errors.address && <Message variant="alert-danger">{errors.address}</Message>}
							</div>
							<div>
								<label htmlFor="city" className="font-medium">
									Ciudad*
								</label>
								<input type="text" id="city" value={formData.city} onChange={handleChange} placeholder="Ciudad" />
								{errors.city && <Message variant="alert-danger">{errors.city}</Message>}
							</div>
							<div>
								<label htmlFor="province" className="font-medium">
									Provincia*
								</label>
								<input type="text" id="province" value={formData.province} onChange={handleChange} placeholder="Provincia" />
								{errors.province && <Message variant="alert-danger">{errors.province}</Message>}
							</div>
							<div>
								<label htmlFor="postalCode" className="font-medium">
									Código Postal*
								</label>
								<input type="text" id="postalCode" value={formData.postalCode} onChange={handleChange} placeholder="Código Postal" />
								{errors.postalCode && <Message variant="alert-danger">{errors.postalCode}</Message>}
							</div>
							<div>
								<label htmlFor="country" className="font-medium">
									País*
								</label>
								<input type="text" id="country" value={formData.country} onChange={handleChange} placeholder="País" />
								{errors.country && <Message variant="alert-danger">{errors.country}</Message>}
							</div>
							<div>
								<label htmlFor="phone" className="font-medium">
									Teléfono*
								</label>
								<input type="text" id="phone" value={formData.phone} onChange={handleChange} placeholder="Teléfono" />
								{errors.phone && <Message variant="alert-danger">{errors.phone}</Message>}
							</div>
							<div className="flex justify-end">
								<button className="btn btn-primary rounded-md py-2 px-6 hover:bg-primary/90 transition-colors">Continuar</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default ShippingScreen;