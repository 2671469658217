import React, { useEffect, useState, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toast from '../LoadingError/Toast';
import Message from '../LoadingError/Error';
import Loading from '../LoadingError/Loading';
import { toast } from 'react-toastify';
import { updateUserProfile } from '../../Redux/Actions/userActions';

const ProfileTabs = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [phone, setPhone] = useState('');
	const toastId = React.useRef<number | string>(0);

	const Toastobjects = {
		pauseOnFocusLoss: false,
		draggable: false,
		pauseOnHover: false,
		autoClose: 2000 // miliseconds
	};

	const dispatch = useDispatch();

	const userDetails = useSelector((state: any) => state.userDetails);
	const { loading, error, user } = userDetails;

	const userUpdateProfile = useSelector((state: any) => state.userUpdateProfile);
	const { loading: updateLoading } = userUpdateProfile;

	useEffect(() => {
		if (user) {
			setName(user.name || '');
			setEmail(user.email || '');
			setPhone(user.phone || '');
		}
	}, [dispatch, user]);

	const submitHandler = (e: FormEvent) => {
		e.preventDefault();
		// Coincidencia de contraseña
		if (password !== confirmPassword) {
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.error('Las contraseñas no coinciden', Toastobjects);
			}
			return;
		} else {
			dispatch(updateUserProfile({ _id: user._id, name, email, phone, password }) as any);
			if (!toast.isActive(toastId.current)) {
				toastId.current = toast.success('Perfil actualizado', Toastobjects);
			}
		}
	};
	return (
		<>
			<Toast />
			{error && <Message variant="alert-danger">{error}</Message>}
			{loading && <Loading />}
			{updateLoading && <Loading />}
			<form className="row container-fluid" onSubmit={submitHandler}>
				<div className="col-md-6 my-2">
					<label htmlFor="account-fn">Nombre de usuario</label>
					<input className="form-control" type="text" required value={name} onChange={(e) => setName(e.target.value)} />
				</div>

				<div className="col-md-6 my-2">
					<label htmlFor="account-email">Dirección de E-Mail</label>
					<input className="form-control" type="email" required value={email} onChange={(e) => setEmail(e.target.value)} />
				</div>
				<div className="col-md-6 my-2">
					<label htmlFor="account-pass">Nueva contraseña</label>
					<input className="form-control" type="password" required value={password} onChange={(e) => setPassword(e.target.value)} />
				</div>
				<div className="col-md-6 my-2">
					<label htmlFor="account-pass">Confirmar contraseña</label>
					<input className="form-control" type="password" required value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
				</div>
				<div className="col-md-6 my-2">
					<label htmlFor="account-pass">Telefono de contacto</label>
					<input type="tel" id="phone" name="phone" placeholder="621221742" value={phone} autoComplete="on" required onChange={(e) => setPhone(e.target.value)}></input>
				</div>
				<button type="submit" className="btn btn-primary text-uppercase">
					Actualizar
				</button>
			</form>
		</>
	);
};

export default ProfileTabs;