import {
  QUESTION_LIST_FAIL,
  QUESTION_LIST_REQUEST,
  QUESTION_LIST_SUCCESS,
} from "../Constants/QuestionConstants";

// Interface for Questions List
export interface Questions {
  id: number;
  question: string;
  answer: string;
}

// Define the state shape
interface QuestionListState {
  loading: boolean;
  questions: Questions[];
  error?: string;
}

// ALL QUESTIONS
export const questionListReducer = (state: QuestionListState = { loading: false, questions: [] }, action: any): QuestionListState => {
  switch (action.type) {
    case QUESTION_LIST_REQUEST:
      return { loading: true, questions: [] };
    case QUESTION_LIST_SUCCESS:
      return { loading: false, questions: action.payload };
    case QUESTION_LIST_FAIL:
      return { loading: false, error: action.payload, questions: [] };
    default:
      return state;
  }
};